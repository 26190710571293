import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { PURGE } from "redux-persist";
import _ from "lodash";

type ObjectProps = {
  name: string;
  price: number;
  priceUpdated: boolean;
  heartsPerSeconds: number;
  count: number;
  totalCount: number;
}

interface SpecialObjectProps {
  name: string;
  priceObjects: {
    [key in Objects]: number;
  };
  priceHearts: number;
  showAfter: {
    [key in Objects]: number;
  };
  got: boolean;
  fg: string;
  bg: string;
  image?: string;
  description: string;
  videoLink?: string;
  changePrice?: { name: Objects, price: number };
  addObjects?: { name: Objects, count: number };
  sfwName?: string;
  sfwImage?: string;
  sfwDescription?: string;
}

export enum Objects {
  Bab = "Bab",
  Beb = "Beb",
  Bob = "Bob",
  Boob = "Boob",
  Bleb = "Bleb",
}

export enum SpecialObjects {
  LoveBox = "LoveBox",
  Meep = "Meep",
  OldApartment = "OldApartment",
  BoobOfPoetry = "BoobOfPoetry",
  Cosplayers = "Cosplayers",
  End = "End",
}

export type ObjectMap = {
  [key in Objects]: ObjectProps;
};

type SpecialObjectMap = {
  [key in SpecialObjects]: SpecialObjectProps;
};

interface GameState {
  totalHearts: number;
  hearts: number;
  objects: ObjectMap;
  specialObjects: SpecialObjectMap;
  beat: boolean;
  isSfw: boolean;
  meepAppears: boolean;
  disableCosplay: boolean;
}

const initialState: GameState = {
  totalHearts: 0,
  hearts: 0,
  objects: {
    [Objects.Bab]: { name: "bab", price: 15, heartsPerSeconds: 0.1, count: 0, totalCount: 0, priceUpdated: false },
    [Objects.Beb]: { name: "beb", price: 100, heartsPerSeconds: 1, count: 0, totalCount: 0, priceUpdated: false },
    [Objects.Bob]: { name: "bob", price: 1100, heartsPerSeconds: 10, count: 0, totalCount: 0, priceUpdated: false },
    [Objects.Boob]: { name: "boob", price: 12000, heartsPerSeconds: 50, count: 0, totalCount: 0, priceUpdated: false },
    [Objects.Bleb]: { name: "bleb", price: 120000, heartsPerSeconds: 1000, count: 0, totalCount: 0, priceUpdated: false }
  },
  specialObjects: {
    [SpecialObjects.LoveBox]: {
      name: "love box",
      priceObjects: {
        [Objects.Bab]: 20,
        [Objects.Beb]: 0,
        [Objects.Bob]: 0,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      priceHearts: 100,
      got: false,
      showAfter: {
        [Objects.Bab]: 5,
        [Objects.Beb]: 0,
        [Objects.Bob]: 0,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      fg: "black",
      bg: "pink",
      image: "box.jpg",
      description: "You have gotten some love! Clicking the heart now is worth more!"
    },
    [SpecialObjects.Meep]: {
      name: "meep",
      priceObjects: {
        [Objects.Bab]: 10,
        [Objects.Beb]: 10,
        [Objects.Bob]: 0,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      priceHearts: 500,
      got: false,
      showAfter: {
        [Objects.Bab]: 5,
        [Objects.Beb]: 1,
        [Objects.Bob]: 0,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      fg: "black",
      bg: "lightskyblue",
      image: "meep.png",
      description: "Meep will now randomly appear on the screen. Catch it for bonus hearts!"
    },
    [SpecialObjects.OldApartment]: {
      name: "old apartment",
      priceObjects: {
        [Objects.Bab]: 20,
        [Objects.Beb]: 20,
        [Objects.Bob]: 0,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      priceHearts: 800,
      got: false,
      showAfter: {
        [Objects.Bab]: 5,
        [Objects.Beb]: 1,
        [Objects.Bob]: 1,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      fg: "black",
      bg: "yellow",
      videoLink: "https://www.youtube.com/embed/KDmUv0r73Vs",
      description: "The old apartment! You're getting 5 Bobs, and they become cheaper!",
      changePrice: { name: Objects.Bob, price: 800 },
      addObjects: { name: Objects.Bob, count: 5 }
    },
    [SpecialObjects.BoobOfPoetry]: {
      name: "boob of poetry",
      sfwName: "bebe's kids",
      priceObjects: {
        [Objects.Bab]: 1,
        [Objects.Beb]: 1,
        [Objects.Bob]: 5,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      priceHearts: 800,
      got: false,
      showAfter: {
        [Objects.Bab]: 1,
        [Objects.Beb]: 1,
        [Objects.Bob]: 10,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 0
      },
      fg: "black",
      bg: "lightGray",
      image: "boobofpoetry.jpg",
      sfwImage: "bebes.jpg",
      description: "Boob of poetry! Gain 2 boobs, and they become cheaper!",
      sfwDescription: "Bebe's kids, you know this one! Gain 2 beebes, and they become cheaper!",
      changePrice: { name: Objects.Boob, price: 8085 },
      addObjects: { name: Objects.Boob, count: 2 }
    },
    [SpecialObjects.Cosplayers]: {
      name: "cosplayers",
      priceObjects: {
        [Objects.Bab]: 0,
        [Objects.Beb]: 0,
        [Objects.Bob]: 0,
        [Objects.Boob]: 0,
        [Objects.Bleb]: 1
      },
      priceHearts: 10000,
      got: false,
      showAfter: {
        [Objects.Bab]: 0,
        [Objects.Beb]: 0,
        [Objects.Bob]: 0,
        [Objects.Boob]: 6,
        [Objects.Bleb]: 4
      },
      fg: "black",
      bg: "Red",
      image: "cosplayers.png",
      description: "Cosplaying is fun!",
      changePrice: { name: Objects.Bleb, price: 100000 },
    },
    [SpecialObjects.End]: {
      name: "End",
      priceObjects: {
        [Objects.Bab]: 15,
        [Objects.Beb]: 15,
        [Objects.Bob]: 15,
        [Objects.Boob]: 15,
        [Objects.Bleb]: 15
      },
      priceHearts: 1,
      got: false,
      showAfter: {
        [Objects.Bab]: 10,
        [Objects.Beb]: 10,
        [Objects.Bob]: 10,
        [Objects.Boob]: 10,
        [Objects.Bleb]: 10
      },
      fg: "white",
      bg: "black",
      image: "end.jpg",
      description: "That's it! (See new button above)"
    }
  },
  beat: false,
  isSfw: false,
  meepAppears: false,
  disableCosplay: false,
};

const currentState: GameState = JSON.parse(JSON.stringify(initialState));

export const gameSlice = createSlice({
  name: "game",
  initialState: currentState,
  reducers: {
    addHearts: (state: GameState, action: PayloadAction<number>) => {
      state.hearts += action.payload;
      state.totalHearts += action.payload;
      state.beat = action.payload > 0;
    },
    addObjects: (state: GameState, action: PayloadAction<{ name: keyof ObjectMap; count: number }>) => {
      state.objects[action.payload.name].count += +action.payload.count;
      state.objects[action.payload.name].totalCount += +action.payload.count;
    },
    setSfw: (state: GameState, action: PayloadAction<boolean>) => {
      state.isSfw = action.payload;
    },
    buyObject: (state: GameState, action: PayloadAction<keyof ObjectMap>) => {
      const object = state.objects[action.payload];
      if (state.hearts >= object.price) {
        state.hearts -= object.price;
        state.objects[action.payload].count++;
        state.objects[action.payload].totalCount++;
      }
    },
    buySpecialObject: (state: GameState, action: PayloadAction<{ name: keyof SpecialObjectMap }>) => {
      const object = state.specialObjects[action.payload.name];
      const diffs = _(object.priceObjects)
        .map((value, key) => {
          return [key, state.objects[key as Objects].count - value];
        })
        .fromPairs()
        .value();
      if (
        !object.got &&
        state.hearts >= object.priceHearts &&
        _(diffs)
          .mapValues((value) => value >= 0)
          .every()
      ) {
        state.hearts -= object.priceHearts;
        let specialObject = state.specialObjects[action.payload.name];
        specialObject.got = true;
        for (const key in diffs) {
          state.objects[key as Objects].count = diffs[key];
        }
        if (specialObject.changePrice) {
          state.objects[specialObject.changePrice.name].price = specialObject.changePrice.price;
          state.objects[specialObject.changePrice.name].priceUpdated = true;
        }
        if (specialObject.addObjects) {
          state.objects[specialObject.addObjects.name].count += specialObject.addObjects.count;
          state.objects[specialObject.addObjects.name].totalCount += specialObject.addObjects.count;
        }
      }
    },
    resetBeat: (state: GameState) => {
      state.beat = false;
    },
    setMeepAppears: (state: GameState, action: PayloadAction<boolean>) => {
      state.meepAppears = action.payload;
    },
    changePrice: (state: GameState, action: PayloadAction<{ name: keyof ObjectMap; price: number }>) => {
      state.objects[action.payload.name].price = action.payload.price;
    },
    loadState: (state: GameState, action: PayloadAction<{game: GameState}>) => {
      return action.payload.game ?  action.payload.game : state;
    },
    updateState: (state: GameState) => {
      for (const object in initialState.specialObjects) {
        const o= object as SpecialObjects;
        state.specialObjects[o]= {...initialState.specialObjects[o], got: state?.specialObjects[o]?.got ?? false};
      }

      if (state.specialObjects.OldApartment.got) {
        state.objects.Bob.priceUpdated = true;
      }

      if (state.specialObjects.BoobOfPoetry.got) {
        state.objects.Boob.priceUpdated = true;
      }

      for (const object in initialState.objects) {
        const o= object as Objects;
        state.objects[o]= {...initialState.objects[o], count: state?.objects[o]?.count ?? 0, totalCount: state?.objects[o]?.totalCount ?? 0,
        price: (state.objects[o]?.priceUpdated ?? false) ? state.objects[o]?.price : initialState.objects[o].price};
      }

      console.log(state);
    },
    setDisableCosplay: (state: GameState, action: PayloadAction<boolean>) => {
      state.disableCosplay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const { addHearts, resetBeat, buyObject, setSfw, setMeepAppears, buySpecialObject, addObjects, changePrice, loadState , updateState, setDisableCosplay} = gameSlice.actions;

export const selectTotalHearts = (state: RootState) => state.game.totalHearts;
export const selectDisableCosplay = (state: RootState) => state.game.disableCosplay;
export const selectMeepAppears = (state: RootState) => state.game.meepAppears;
export const selectIsSfw = (state: RootState) => state.game.isSfw;
export const selectHearts = (state: RootState) => state.game.hearts;
export const selectBeat = (state: RootState) => state.game.beat;
export const selectObjects = (state: RootState) => state.game.objects;
export const selectSpecialObjects = (state: RootState) => state.game.specialObjects;
export const selectHeartsPerSeconds = (state: RootState): number =>
  _(selectObjects(state))
    .map((item) => item.heartsPerSeconds * item.count)
    .sum() as number;

export default gameSlice.reducer;
