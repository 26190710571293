import * as React from "react";
// @ts-ignore
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image, Input,
  keyframes, Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Progress,
  Text, useColorMode, useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "./hooks";
import {
  addHearts, addObjects,
  buyObject,
  buySpecialObject, loadState, ObjectMap,
  Objects,
  resetBeat,
  selectBeat, selectDisableCosplay,
  selectHearts,
  selectHeartsPerSeconds,
  selectIsSfw,
  selectMeepAppears,
  selectObjects,
  selectSpecialObjects, selectTotalHearts, setDisableCosplay,
  setMeepAppears,
  setSfw,
  SpecialObjects, updateState
} from "./gameSlice";
import { persistor, store } from "./store";
import _, { random } from "lodash";

const MEEP_RATE = 5;
const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
                 `;


export const App = () => {
    const totalHearts = useAppSelector(selectTotalHearts);
    const hearts = useAppSelector(selectHearts);
    const hps = useAppSelector(selectHeartsPerSeconds);
    const objects = useAppSelector(selectObjects);
    const specialObjects = useAppSelector(selectSpecialObjects);
    const isSfw = useAppSelector(selectIsSfw);
    const meepAppears = useAppSelector(selectMeepAppears);
    const disableCosplay = useAppSelector(selectDisableCosplay);

    const [meepSize, setMeepSize] = useState(10);
    const [meepRate, setMeepRate] = useState(MEEP_RATE);
    const { colorMode, toggleColorMode } = useColorMode();

    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(updateState());
    }, [dispatch]);

    useEffect(() => {
      const interval = setInterval(() => {
        if (hps > 0) {
          dispatch(addHearts(hps));
        }

        if (!meepAppears && random(0, 100) < meepRate) {
          dispatch(setMeepAppears(true));
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [dispatch, hps, meepAppears, meepRate]);

    const endModal = CreateModal(
      <Text>That's It!</Text>,
      <Box>
        I love you so much Dror.
        <br />
        You bring me light, you bring me joy.
        <br />
        I am so lucky that the stars aligned for us to meet.
        <br />
        That's the closest I've been to believing in destiny.
        <br />
        I am not a great writer like you, I can't express myself in words and prose like you can.
        <br />
        Hopefully this game have you even a sliver of what I feel when you make me anything.
        <br />
        What I feel when you're there for me.
        <br />
        I love you. So so much.
        <br />
        P.S - for the real ending <Link color="blue" href="https://youtu.be/od9MRX3w2DA">click here</Link>
      </Box>,
      <Text>Close</Text>
    );

    let [addHeartsCount, setAddHeartsCount] = useState(0);
    let [hideUi, setHideUi] = useState(false);

    let buttonBg = useColorModeValue("green.200", "green.500");

    let boxColor = useColorModeValue("blue.200", "blue.800");

    return (
      <Box>
        <BackgroundContainer totalHearts={totalHearts} />
        <Button bg={buttonBg} margin={3} onClick={() => setHideUi(hideUi => !hideUi)}>Toggle UI</Button>
        <Button onClick={() => toggleColorMode()}>
          Toggle {colorMode === "light" ? "Dark" : "Light"}
        </Button>
        <Button bg={buttonBg} margin={3} onClick={async () => await persistor.purge()}>Reset Game</Button>
        <Button bg={buttonBg} margin={3} onClick={() => dispatch(setSfw(!isSfw))}>{isSfw ? "" : "N"}SFW Mode ON</Button>
        <Button bg={buttonBg} margin={3} onClick={() => {
          function download(content: any, fileName: string, contentType: string) {
            const a = document.createElement("a");
            const file = new Blob([content], { type: contentType });
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          }

          download(JSON.stringify(store.getState()), "state.json", "application/json");
        }}>Save progress to file</Button>

        <Button bg={buttonBg} margin={3}>
          <label htmlFor="file">Load from file</label>
          <input type="file" id="file" style={{ display: "none" }} onChange={evt => {
            const reader = new FileReader();

            reader.onload = function(evt) {
              if (evt.target === null) {
                return;
              }
              if (evt.target.readyState !== 2) return;
              if (evt.target.error) {
                alert("Error while reading file");
                return;
              }

              dispatch(loadState(JSON.parse(evt.target.result as string)));
            };

            if (!evt.target.files) {
              return;
            }
            reader.readAsText(evt.target.files[0]);
          }} /></Button>
        <Button display={(specialObjects.Cosplayers?.got || false) ? "inline-block" : "none"} bg={buttonBg} margin={3} onClick={() => dispatch(setDisableCosplay(!disableCosplay))}>Toggle Cosplay</Button>
        <Box padding={1} margin={1} borderRadius={"1em"} bg={boxColor} as={"button"}>Meep size:
          <NumberInput value={meepSize} min={1} max={50} onChange={(e, n) => setMeepSize(+n)}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <input type={"range"} value={meepSize} min={1} max={50} onChange={e => setMeepSize(+e.target.value)} />
        </Box>
        <Box padding={1} borderRadius={"1em"} bg={boxColor} margin={1} as={"button"}>Meep rate:
          <NumberInput value={meepRate} min={1} max={100} onChange={(e, n) => setMeepRate(+n)}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <input type={"range"} value={meepRate} min={1} max={50} onChange={e => setMeepRate(+e.target.value)} />
        </Box>
        <Box display="none" id="wewlad">
          <Button bg={buttonBg} margin={3} onClick={() => dispatch(addHearts(addHeartsCount))}>Add <Input type={"number"} value={addHeartsCount} onChange={
            (e) => setAddHeartsCount(+e.target.value)} /> Hearts</Button>
          {Object.entries(objects).map(([o, _]) => <Button key={o} bg={buttonBg} margin={3}
                                                           onClick={() => dispatch(addObjects({ name: o as Objects, count: 10 }))}>Get 10 {o}</Button>)}
        </Box>
        <Box textAlign="center" fontSize="xl" visibility={hideUi ? "hidden" : "visible"} opacity={hideUi ? 0 : 1} transition={"visibility 1s" +
          " linear,opacity 1s" +
          " linear"}>
          <Grid templateRows={{ sm: "1fr 1fr 1fr", md: "1fr 2fr" }} templateColumns={{ sm: "1fr", md: "1fr 1fr" }}>
            <GridItem rowSpan={2}><Heart gotLoveBox={specialObjects?.LoveBox?.got ?? false} objects={objects} /></GridItem>
            <GridItem>
                <Box display={"inline-block"} bgColor={boxColor} padding={5} margin={5} borderRadius={"1em"}>
                  <Text fontSize="xl">
                    Hearts: {hearts.toFixed(2)} (+{hps.toFixed(2)} per second){" "}
                  </Text>
                  {Object.entries(objects).map(([key, object]) => {
                    const name = isSfw && object.name === "boob" ? "beeb" : object.name;
                    const totalHeartsCost = object.heartsPerSeconds * object.count;
                    return (
                      <Box key={key}>
                        <Text fontSize="xl" as={"div"}>
                          {name}s: {object.count} {totalHeartsCost > 0 ? "(" + totalHeartsCost.toFixed(2) + " HPS)" : ""}
                          <Button disabled={object.price > hearts} display="inline-block" margin={1}
                                  onClick={() => dispatch(buyObject(key as Objects))}>
                            Buy a {name}- {object.price} hearts{" "}
                          </Button>
                          <Box background={"tomato"} display="inline-block" borderRadius="lg" padding={2} margin={1}>
                            Gives {object.heartsPerSeconds} hearts per second
                          </Box>
                        </Text>
                      </Box>
                    );
                  })}

                  {specialObjects.End.got && <Button bgColor={buttonBg} w="xl" onClick={() => endModal.onOpen()}>
                    Click here for the ending!
                    {endModal.modal}
                  </Button>}
                </Box></GridItem>
            <GridItem>
              <Box>
                <Flex alignItems={"space-evenly"} justifyContent={"center"} flexWrap={"wrap"}>
                  {_(specialObjects)
                    .pickBy((x) => x.got || _(x.showAfter).every((v, k) => objects[k as Objects].totalCount >= v))
                    .map((object, specialObject: SpecialObjects) => {
                      return (
                        <Box minW="12em" key={specialObject} bgColor={object.bg} color={object.fg} borderRadius="1em" padding={5} margin={5}>
                          <Text fontSize="xl" as={"div"}>
                            {_.capitalize(isSfw && object.sfwName ? object.sfwName : object.name)} - {object.got ? "✅" : "❌"}
                          </Text>
                          <Button
                            disabled={!_(object.priceObjects).every((v, k) => objects[k as Objects].count >= v) || object.priceHearts > hearts || object.got}
                            display="inline-block"
                            margin={1}
                            onClick={() => dispatch(buySpecialObject({ name: specialObject }))}>
                            Buy
                          </Button>
                          {object.got ? (
                            <Box maxW="10em">
                              {object.videoLink ?
                                <iframe width="100%" src={object.videoLink} title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen /> : <Image src={isSfw && object.sfwImage ? object.sfwImage : object.image} />
                              }
                              <Text fontSize="xl" as={"div"}>
                                {isSfw && object.sfwDescription ? object.sfwDescription : object.description}
                              </Text>
                            </Box>
                          ) : (
                            <>
                              <Meter meterName="Hearts" from={Math.floor(hearts)} to={object.priceHearts} disabled={object.got} />
                              {_(object.priceObjects)
                                .pickBy((val) => val > 0)
                                .map((val, key: Objects) => (
                                  <Box key={specialObject + "-" + key}>
                                    <Meter meterName={key} from={objects[key].count} to={val} disabled={object.got} />
                                  </Box>
                                ))
                                .value()}
                            </>
                          )}
                        </Box>
                      );
                    })
                    .value()}
                </Flex>
              </Box>
            </GridItem>
          </Grid>


        </Box>
        {specialObjects.Meep.got && <Meep size={meepSize} />}
      </Box>
    );
  }
;

export const Meter = ({ meterName, from, to, key, disabled }: { meterName: string; from: number; to: number; key?: string; disabled: boolean }) => {
  let disabledColor = useColorModeValue("gray.300", "gray.800");
  return (
    <Box padding={2} marginTop={2} borderRadius="0.5em" key={key} bgColor={disabled ? disabledColor : from >= to ? "lightgreen" : "tomato"}>
      {meterName}: {from}/{to} <Progress value={100 * from / to} />
    </Box>
  );
};

export const Meep = (props: { size: number }) => {
  const dispatch = useAppDispatch();
  const meepAppears = useAppSelector(selectMeepAppears);
  const ref = useRef<HTMLImageElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (ref.current) {
      setDimensions({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
    }
  }, [ref]);

  const [meepState, setMeepState] = useState({
    init: false,
    x: 0,
    y: 0,
    rotate: 0,
    targetX: 0,
    targetY: 0,
    frames: keyframes``
  });

  if (meepAppears && !meepState.init) {
    let targetX = random(0, window.innerWidth - dimensions.width);
    let targetY = random(0, window.innerHeight - dimensions.height);
    let rotate = random(0, 360);
    let x = random(0, window.innerWidth - dimensions.width);
    let y = random(0, window.innerHeight - dimensions.height);
    setMeepState({
      targetX,
      targetY,
      init: true,
      x: x,
      y: y,
      rotate: rotate,
      frames: keyframes`
      0% { transform: translate(0px,0px); }
      100% { transform: translate(${targetX - x}px,${targetY - y}px); }`
    });
  }
  const { modal, onOpen } = CreateModal(<Text>You got meep!</Text>, <Text>Enjoy 1000 hearts!</Text>, <Text>Thanks</Text>, () => {
    dispatch(addHearts(1000));
    resetMeep();
  });
  const resetMeep = () => {
    dispatch(setMeepAppears(false));
    setMeepState({
      ...meepState,
      init: false
    });
  };

  return (
    <Box>
      <Image
        ref={ref}
        src={"meep.png"}
        position={"absolute"}
        maxWidth={props.size + "em"}
        display={meepState.init ? "block" : "none"}
        left={meepState.x}
        top={meepState.y}
        animation={meepState.init ? `${meepState.frames} 1s linear` : undefined}
        onAnimationEnd={resetMeep}
        onDrag={() => {
          onOpen();
        }}
        onClick={() => {
          onOpen();
        }}
      />
      {modal}
    </Box>
  );
};

function CreateModal(title: JSX.Element, body: JSX.Element, closeText: JSX.Element, onCloseFunc?: () => void): { onOpen: () => void; modal: JSX.Element } {
  const { isOpen, onOpen, onClose } = useDisclosure({ onClose: onCloseFunc });
  return {
    modal: (
      <>
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{body}</ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                {closeText}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    ),
    onOpen
  };
}

const beat = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
 `;

const specialHueMap: { [key in Objects]: { fg: string; bg: string }[] } = {
  Bab: [
    { fg: "black", bg: "pink" },
    { fg: "teal", bg: "pink" },
    { fg: "orange", bg: "pink" },
    { fg: "gold", bg: "pink" }
  ],
  Beb: [
    { fg: "pink", bg: "teal" },
    { fg: "tomato", bg: "teal" },
    { fg: "silver", bg: "teal" },
    { fg: "gold", bg: "teal" }
  ],
  Bob: [
    { fg: "pink", bg: "transparent" },
    { fg: "teal", bg: "transparent" },
    { fg: "silver", bg: "transparent" },
    { fg: "gold", bg: "transparent" }
  ],
  Boob: [
    { fg: "pink", bg: "transparent" },
    { fg: "teal", bg: "transparent" },
    { fg: "silver", bg: "transparent" },
    { fg: "gold", bg: "transparent" }
  ],
  Bleb: [
    { fg: "black", bg: "orange" },
    { fg: "teal", bg: "orange" },
    { fg: "lightblue", bg: "orange" },
    { fg: "red", bg: "orange" }
  ]
};


const ObjectDisplay = ({
  objectType,
  heart,
  position,
  total
}: { objectType: Objects; heart: React.RefObject<HTMLImageElement> | null; position: number, total: number }) => {
  const isSfw = useAppSelector(selectIsSfw);
  const objects = useAppSelector(selectObjects);
  let disableCosplay = useAppSelector(selectDisableCosplay);
  const mustache = (useAppSelector(selectSpecialObjects).Cosplayers?.got || false) && !disableCosplay;
  const values: { hue: { fg: string; bg: string }; digit: number }[] = _(objects[objectType].count.toString().split(""))
    .reverse()
    .flatMap((t, i) => _.times(+t).map((_) => ({ hue: specialHueMap[objectType][i % specialHueMap[objectType].length], digit: +i })))
    .reverse()
    .value();

  const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [inAnimation, setInAnimation] = useState(false);
  useEffect(() => {
    const current = heart?.current;
    if (current) {
      current.addEventListener("animationstart", () => {
        setInAnimation(true);
      });
      current.addEventListener("animationend", () => {
        setInAnimation(false);
      });
    }
    return () => {
      if (current) {
        current.removeEventListener("animationstart", () => {
          setInAnimation(true);
        });
        current.removeEventListener("animationend", () => {
          setInAnimation(false);
        });
      }
    };
  }, [heart]);

  useEffect(() => {
    if (inAnimation) {
      return;
    }
    if (heart?.current) {
      const rect = heart.current.getBoundingClientRect();
      setRect({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
    }
  }, [heart, inAnimation, setRect]);

  return (
    <Box position="relative">
        {values.map((obj, i: number) => {
          const width = rect.width;
          const height = rect.height;

          const angle = ((position / total) * 360) + (i * (40 / values.length));
          const radius = Math.sqrt((Math.pow(height, 2) + Math.pow(width, 2))) / 2;


          let isImage = false;
          let content: string = objectType;
          switch (objectType) {
            case Objects.Bab:
              break;
            case Objects.Beb:
              break;
            case Objects.Bob:
              isImage = true;
              content = "bob.png";
              break;
            case Objects.Boob:
              if (!isSfw) {
                isImage = true;
                content = "boob.png";
              } else {
                content = "beeb";
              }
              break;
          }
          const objectDiv = isImage ? (
            <Image display={"inline-block"} width={width / 10} height={height / 10} src={content} />
          ) : (
            <Box as={"span"}>{content}</Box>
          );

          const bounce = keyframes`
                  0% { transform: rotate(${angle}deg) translate(${radius}px) rotate(-${angle}deg); }
                  50% { transform: rotate(${angle}deg) translate(0px) rotate(-${angle}deg); }
                  100% { transform: rotate(${angle}deg) translate(${radius}px) rotate(-${angle}deg); }
                 `;

          return (
            <Box
              key={i}
              animation={`${bounce} ease-in 3s -${(position * 2 / total) + (i / (values.length + 1))}s infinite`}
              style={{
                zIndex: 10,
                position: "absolute",
                left: `44%`,
                top: `${height / 2 * 0.95}px`,
                whiteSpace: "nowrap",
                fontSize: `${Math.max(((width + height) / 2) / 20, 12)}px`
              }}
              bgColor={obj.hue.bg}
              color={obj.hue.fg}
              fontFamily={"\"Comic Sans MS\", \"Comic Sans\", cursive;"}
              borderRadius={"full"}
            >
              {obj.digit !== 0 ? `${10 ** obj.digit}x` : ""}
              {objectDiv}
              {mustache ?
                <Image display={"block"} width={width / 10} height={height / 20} position={"absolute"} left={0} top={"50%"} src={"mustache.png"} /> : ""}
            </Box>
          );
        })}
    </Box>
  );
};

const Heart = ({ gotLoveBox, objects }: { gotLoveBox: boolean, objects: ObjectMap }) => {
  const heart = useRef<HTMLImageElement>(null);
  const dispatch = useAppDispatch();
  let keys = Object.keys(objects).filter(o => objects[o as Objects].count > 0) as Objects[];
  return <Box>
    {keys.map((key, i) => <ObjectDisplay key={key} objectType={key} heart={heart} position={i} total={keys.length} />)}
    <Image
      width={"70%"}
      m={"auto"}
      src={gotLoveBox ? "heart2.png" : "heart.png"}
      style={{ zIndex: -999 }}
      ref={heart}
      animation={useAppSelector(selectBeat) ? `${beat} 1 0.2s linear` : undefined}
      cursor="pointer"
      onClick={() => {
        dispatch(addHearts(gotLoveBox ? 5 : 1));
      }}
      onAnimationEnd={() => dispatch(resetBeat())}
    />
  </Box>;
};

const BackgroundContainer = ({ totalHearts }: { totalHearts: number }) => {
  const hearts = [100, 200, 400, 800, 1600, 3200, 6400, 8000, 12800, 25600, 51200, 75600, 102400];
  const images = _.range(0, 12).map(i => (i + 1) + ".jpg");

  return <Box position={"absolute"} top={0} left={0} zIndex={-9999} height={"100%"} width={"100%"} margin={0} padding={0}>
    <Grid height={"100%"} width={"100%"}
          templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)" }}
          justifyItems={"center"} alignItems={"center"}
    >
      {images.map((image, i) =>
        (
          <GridItem key={i}>
            <Image
              src={image}
              display={totalHearts >= hearts[i] ? "inline-block" : "none"}
              animation={`${fadeIn} 2s;`}
            />
          </GridItem>
        ))
      }
    </Grid>
  </Box>;
};
